import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 양사간 마케팅 협력을 위한 전략적 파트너십 구축… ‘통신-WEB3 이종산업 간 시너지 기틀 마련’",
  "| 일상 생활에 도움이 되는 혜택을 통합적으로 제공할 계획",
  "| 리워드, 멤버십 프로그램 등 혜택 형태 다각화 모색",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/39/39_img1.png" style={{ width: "300px", margin: "auto" }} alt="CI" />
      </div>
      <div className="desc">&lt;(좌)밀크파트너스 CI (우) 스테이지파이브 CI&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 밀크파트너스(대표 조정민)와 양사간 유저 마케팅 협력을 위한 파트너십 구축을 목적으로 하는 MOU를
      체결하였다고 29일 밝혔다.
      <br />
      <br />
      포인트 통합 플랫폼 밀크(MiL.k)와 통신 테크 기업 스테이지파이브는 이번 MOU를 계기로 통신 및 WEB3 분야에서 각 사가 보유한
      기술과 역량을 결합하여 사업영역을 확대하고, 이용자들의 실질적 혜택을 극대화한다는 계획이다.
      <br />
      <br />
      WEB3란 블록체인 기술을 기반으로 데이터 소유를 개인화 하는 3세대 인터넷 환경을 의미하며, 밀크파트너스는 이러한 WEB3 기술을
      활용하여 블록체인 기반 포인트 통합 플랫폼 밀크(MiL.k)를 안정적으로 운영하고 있다. 이용자는 밀크(MiL.k) 플랫폼을 통해
      여행·여가·쇼핑·라이프스타일 등 다양한 분야 기업들의 마일리지 포인트를 한 번에 관리하고 교환할 수 있다.
      <br />
      <br />
      양사는 이번 전략적 제휴를 통해 양사 이용자들을 대상으로 일상 생활에 실질적인 도움이 되는 혜택을 제공해 나아갈 예정이다.
      ▲핀다이렉트 이용자를 대상으로 밀크(MiL.k) 얼라이언스 혜택을 통합적으로 제공하고 ▲핀다이렉트만의 이용자 리워드 및 멤버십
      프로그램도 함께 구상 중이다.
      <br />
      <br />
      스테이지파이브 관계자는 “이종산업간의 융합을 통해 보다 큰 시너지를 창출할 수 있을 뿐 아니라, 통신고객 멤버십의 새로운
      패러다임을 제시할 수 있을 것으로 기대한다”며, “고객 혜택 관점에서의 새로운 혁신이 될 것” 이라고 강조했다.
      <br />
    </p>
  </div>
)

const press39 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 8월 29일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 밀크파트너스와 MOU 체결… ‘유저 혜택 극대화 및 사업영역 확대 기대’"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press39
